import React, { useEffect } from "react";
import { View, Text, StyleSheet, ScrollView, Image } from "react-native";
// import StatsTable from './widgets/StatsTable'
import Table from "@mui/joy/Table";
import ReactCountryFlag from "react-country-flag";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ComposedChart,
  ReferenceDot,
} from "recharts";
import { AreaChart, Area } from "recharts";

import moment from "moment";
import { getAlpha2Code } from "i18n-iso-countries";

// A common widget card component
const WidgetCard = ({ title, description, render, width }) => (
  <View style={[styles.widgetCard, width && { width }]}>
    <View style={styles.widgetHeader}>
      <Text style={styles.widgetTitle}>{title}</Text>
    </View>
    {/* {<Text style={styles.widgetDescription}>{description}</Text>} */}
    {render || (
      <View style={styles.placeholder}>
        <Text style={styles.placeholderText}>Placeholder Content</Text>
      </View>
    )}
    {/* {<View style={styles.branding}>
      <Text style={styles.brandingText}>Powered by Itaú</Text>
    </View>} */}
  </View>
);

// A section component that renders a section title and a grid of widget cards
const WidgetSection = ({ sectionTitle, widgets }) => (
  <View style={styles.section}>
    <View style={{backgroundColor:'white', width:'100%', paddingVertical:12}}>
    <Text style={[styles.sectionTitle, {color:'black'}]}>{sectionTitle}</Text>
    </View>
    <View style={styles.sectionGrid}>
      {widgets.map((widget, index) => (
        <WidgetCard {...widget} key={index} />
      ))}
    </View>
  </View>
);

const LiveWinPredictor = ({ id }) => {
  const match = data.matches.find((obj) => obj.id === id);
  if (!match?.odds_timeline) return;

  const series = match.odds_timeline.map((obj, i) => {
    return {
      time: i,
      p1: obj[0].probability,
      p2: obj[1].probability,
    };
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={series}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="p1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="p2" stroke="red" fill="red" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

//---------
//KPIs
//---------
const bg = `https://www.transparentpng.com/download/pattern/p3NBVZ-data-security-and-privacy-software-services-safe-data.png`;
const orange = "#F26C37";
const volt = "#C1fC45";
const BackgroundPattern = ({ opacity, tintColor }) => {
  return (
    <Image
      style={{
        height: "100%",
        width: "100%",
        opacity: 0,
        resizeMode: "cover",
        position: "absolute",
        bottom: 0,
        right: 0,
        tintColor,
      }}
      source={{ uri: bg }}
    />
  );
};

const KPI = ({
  backgroundColor,
  color,
  accent,
  item,
  reducedOpacity,
  brand,
}) => {
  const width = 300;
  const aspectRatio = 1.5;
  // const borderRadius = width / 8;
  const padding = borderRadius / 1.5;
  if (!color) color = "black";
  if (!backgroundColor) backgroundColor = "white";
  if (!accent) accent = orange;

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          padding,
        }}
      >
        <Text
          style={{
            color: accent,
            fontSize: 18,
            fontFamily: "ClanOT-Bold",
            opacity: 1,
            textTransform: "uppercase",
          }}
        >
          {item.header}
        </Text>
        <Text
          style={{
            color,
            fontSize: 34,
            fontFamily: "ClanOT-Black",
            textTransform: "uppercase",
            opacity: 1,
            textAlign: "left",
            marginTop: 6,
          }}
        >
          {item.title}
        </Text>

        <Text
          style={{
            color: accent,
            fontSize: 48,
            fontFamily: "ClanOT-Black",
            opacity: 1,
            textAlign: "left",
            marginTop: 24,
            marginBottom: -10,
          }}
        >
          {item.stat}
        </Text>
        {item.caption && (
          <Text
            style={{
              color,
              fontSize: 17,
              fontFamily: "ClanOT-Medium",
              opacity: reducedOpacity || 0.5,
              marginTop: 8,
            }}
          >
            {item.caption}
          </Text>
        )}
      </View>

      <Image
        style={{
          height: "120%",
          aspectRatio: 1,
          resizeMode: "contain",
          position: "absolute",
          bottom: -40,
          right: -width / 2.5,
        }}
        source={{ uri: item.image }}
      />
      {item.brand && (
        <BrandLogo
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
          }}
        />
      )}
    </View>
  );
};

const red = "#BF205D";
const green = "#20BF6F";
const purple = "#8537F2";

const borderRadius = 32;
const defaultImage =
  "https://photoresources.wtatennis.com/photo-resources/2024/04/22/b6184f99-e557-434c-a6a7-cba7d47bef27/Jabeur-Torso_316847.png?width=950";

const PlayerForm = ({}) => {
  const width = 600;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Sebastian Korda`,
    stat: `12%`,
    caption: `to win`,
    image: `https://www.atptour.com/-/media/alias/player-gladiator/K0AH`,
  };

  const results = [
    {
      round: "l. Klein",
      opp:"",
      image: 'https://ausopen.com/sites/default/files/styles/420x/public/202401/17/lukas-klein.png?itok=2OoJ259M',
      date: moment().subtract(2, "days").format("M/D"),
    },
    {
      round: "d. Vukic",
      image: 'https://www.atptour.com/-/media/alias/player-gladiator/V832',
      date: moment().subtract(3, "days").format("M/D"),
    },
    {
      round: "l. Auger-Alliasime",
      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUt5Uc_nTMehVh2AjLyvMhV7QPxynZ0fImMA&s',
      date: moment().subtract(5, "days").format("M/D"),
    },
    {
      color: red,
      round: "d. Kecmanovic",
      image: 'https://www.atptour.com/-/media/alias/player-gladiator/KI95',
      date: moment().subtract(18, "days").format("M/D"),
    },
    {
      round: "d. Kokkinakis",
      image: 'https://lavercup.com/wp-content/uploads/2018/07/2024-Kokkinakis-Profile.png',
      date: moment().subtract(20, "days").format("M/D"),
    },
  ];

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          padding,
        }}
      >
        <Text
          style={{
            color: orange,
            fontSize: 18,
            fontFamily: "ClanOT-Bold",
            opacity: 1,
            textTransform: "uppercase",
          }}
        >
          Last 5 Matches
        </Text>
        <Text
          style={{
            color,
            fontSize: 34,
            fontFamily: "ClanOT-Black",
            textTransform: "uppercase",
            opacity: 1,
            textAlign: "left",
            marginTop: 6,
          }}
        >
          {item.title}
        </Text>

        <Image
          style={{
            height: "120%",
            aspectRatio: 1,
            resizeMode: "contain",
            position: "absolute",
            bottom: -40,
            right: -60,
          }}
          source={{ uri: item.image }}
        />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          {results.map((obj) => (
            <View
              style={[
                {
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor,
                  padding: 8,
                  paddingHorizontal:0,
                  borderRadius: 16,
                  marginRight: 16,
                },
                styles.widgetShadowLite,
              ]}
            >
              <Image
                style={{
                  height: 72,
                  width: 72,
                  // borderRadius: 100,
                  backgroundColor: "white",
                  resizeMode: "contain",
                }}
                source={{ uri: obj.image }}
              />
              <View
                style={{
                  width: 40,
                  height: 6,
                  borderRadius: 2,
                  backgroundColor: obj.color || green,
                  marginBottom: 8,
                }}
              />
              {/* {<Text
                style={{
                  fontFamily: "ClanOT-News",
                  fontSize: 12,
                  color,
                  textAlign: "center",
                  width:80
                }}
                numberOfLines={1}
              >
                {obj.round}
              </Text>} */}
              <Text
                style={{
                  fontFamily: "ClanOT-News",
                  fontSize: 12,
                  opacity:0.5,
                  color,
                  textAlign: "center",
                }}
              >
                {obj.date}
              </Text>
            </View>
          ))}
        </View>
      </View>

      {item.brand && (
        <Image
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
            resizeMode: "contain",
          }}
          source={{
            uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Ita%C3%BA_Unibanco_logo_2023.svg/150px-Ita%C3%BA_Unibanco_logo_2023.svg.png",
          }}
        />
      )}
    </View>
  );
};

const PlayerTournamentForm = ({}) => {
  const width = 600;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Ons Jabeur`,
    stat: `12%`,
    caption: `to win`,
    image: `https://photoresources.wtatennis.com/photo-resources/2024/04/22/b6184f99-e557-434c-a6a7-cba7d47bef27/Jabeur-Torso_316847.png?width=950`,
  };

  const results = [
    {
      round: "R32\nMelbourne",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Australian_Open_Logo_2017.svg/1200px-Australian_Open_Logo_2017.svg.png",
      date: moment().subtract(2, "days").format("M/D"),
    },
    {
      round: "R16\nAdelaide",
      image: defaultImage,
      date: moment().subtract(3, "days").format("M/D"),
    },
    {
      round: "QF\nVienna",
      image: defaultImage,
      date: moment().subtract(5, "days").format("M/D"),
    },
    {
      color: red,
      round: "R16\nBeijing",
      image: defaultImage,
      date: moment().subtract(18, "days").format("M/D"),
    },
    {
      round: "R64\nTokyo",
      image: defaultImage,
      date: moment().subtract(20, "days").format("M/D"),
    },
  ];

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          padding,
        }}
      >
        <Text
          style={{
            color: orange,
            fontSize: 18,
            fontFamily: "ClanOT-Bold",
            opacity: 1,
            textTransform: "uppercase",
          }}
        >
          Last 5 Tournaments
        </Text>
        <Text
          style={{
            color,
            fontSize: 34,
            fontFamily: "ClanOT-Black",
            textTransform: "uppercase",
            opacity: 1,
            textAlign: "left",
            marginTop: 6,
          }}
        >
          {item.title}
        </Text>

        <Image
          style={{
            height: "120%",
            aspectRatio: 1,
            resizeMode: "contain",
            position: "absolute",
            bottom: -40,
            right: -60,
          }}
          source={{ uri: item.image }}
        />

        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          {results.map((obj) => (
            <View
              style={[
                {
                  justifyContent: "center",
                  minWidth: 80,
                  alignItems: "center",
                  backgroundColor,
                  padding: 0,
                  borderRadius: 16,
                  overflow: "hidden",
                  marginRight: 16,
                },
                styles.widgetShadowLite,
              ]}
            >
              <Image
                style={{
                  height: 42,
                  width: "100%",
                  aspectRatio: 1,
                  marginBottom: 8,
                  // borderRadius: 8,
                  backgroundColor: "white",
                  resizeMode: "cover",
                }}
                source={{ uri: obj.image }}
              />
              <View style={{ padding: 8 }}>
                <Text
                  style={{
                    fontFamily: "ClanOT-News",
                    fontSize: 12,
                    color,
                    textAlign: "center",
                  }}
                >
                  {obj.round}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      {item.brand && (
        <Image
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
            resizeMode: "contain",
          }}
          source={{
            uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Ita%C3%BA_Unibanco_logo_2023.svg/150px-Ita%C3%BA_Unibanco_logo_2023.svg.png",
          }}
        />
      )}
    </View>
  );
};

const BrandLogo = ({ style }) => {
  return (
    <View style={[style, { justifyContent: "center", alignItems: "center" }]}>
      <View
        style={{
          height: "85%",
          aspectRatio: 1,
          backgroundColor: "white",
          position: "absolute",
          borderRadius: 10,
        }}
      />
      <Image
        style={{ height: "100%", width: "100%", resizeMode: "contain" }}
        source={{
          uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Ita%C3%BA_Unibanco_logo_2023.svg/150px-Ita%C3%BA_Unibanco_logo_2023.svg.png",
        }}
      />
    </View>
  );
};

const PlayerRankingTimeline = ({}) => {
  const width = 500;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Learner Tien`,
    stat: `12%`,
    caption: `to win`,
    image: `https://photoresources.wtatennis.com/photo-resources/2024/04/22/b6184f99-e557-434c-a6a7-cba7d47bef27/Jabeur-Torso_316847.png?width=950`,
  };

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          // padding,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding,
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: orange,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 1,
                textTransform: "uppercase",
              }}
            >
              Ranking History
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
                marginTop: 6,
              }}
            >
              {item.title}
            </Text>
          </View>
          <View
            style={{
              // flex: 1,
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Text
              style={{
                color,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 0.5,
                textTransform: "uppercase",
              }}
            >
              Rank
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "right",
                marginTop: 6,
              }}
            >
              32
            </Text>
          </View>
        </View>
        <View
          style={[
            {
              width: "100%",
              height: 250,
            },
          ]}
        >
          <RenderAreaChartAxes />
        </View>
      </View>

      {
        <BrandLogo
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
          }}
        />
      }
    </View>
  );
};

const PlayerWinPredictor = ({}) => {
  const width = 500;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Tommy Paul`,
    stat: `12%`,
    caption: `to win`,
    image: `https://photoresources.wtatennis.com/photo-resources/2024/04/22/b6184f99-e557-434c-a6a7-cba7d47bef27/Jabeur-Torso_316847.png?width=950`,
  };

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          // padding,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding,
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: orange,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 1,
                textTransform: "uppercase",
              }}
            >
              Live Win Predictor
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
                marginTop: 6,
              }}
            >
              {item.title}
            </Text>
          </View>
          <Text
            style={{
              color: orange,
              fontSize: 48,
              fontFamily: "ClanOT-Black",
              textTransform: "uppercase",
              // opacity: 0.5,
              textAlign: "left",
              marginTop: 6,
            }}
          >
            78%
          </Text>
        </View>

        {/* {<Image
          style={{
            height: "110%",
            aspectRatio: 1,
            resizeMode: "contain",
            position: "absolute",
            bottom: -40,
            right: -120,
          }}
          source={{ uri: item.image }}
        />} */}

        <View
          style={[
            {
              width: "100%",
              height: 150,
              // marginTop: 24,
              // marginRight:-padding,
              // marginLeft:-padding,
              // marginBottom: -padding,
              // backgroundColor:'white',
              // padding:16,
              // borderRadius:16,
            },
          ]}
        >
          <RenderAreaChart />
        </View>
      </View>

      {
        <BrandLogo
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
          }}
        />
      }
    </View>
  );
};

const MatchMomentum = ({}) => {
  const width = 500;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Ons Jabeur`,
    stat: `12%`,
    caption: `to win`,
    image: `https://photoresources.wtatennis.com/photo-resources/2024/04/22/b6184f99-e557-434c-a6a7-cba7d47bef27/Jabeur-Torso_316847.png?width=950`,
  };

  const series = [
    {
      name: "Page A",
      uv: 0,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 2400,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: -3000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 200,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: -2000,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page E2",
      uv: -1800,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: -250,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 2160,
      pv: 4300,
      amt: 2100,
    },
  ];

  const gradientOffset = () => {
    const dataMax = Math.max(...series.map((i) => i.uv));
    const dataMin = Math.min(...series.map((i) => i.uv));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          // padding,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding,
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: green,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 1,
                textTransform: "uppercase",
              }}
            >
              Match Momentum
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
                marginTop: 6,
              }}
            >
              {item.title}
            </Text>
          </View>
          <Text
            style={{
              color: green,
              fontSize: 48,
              fontFamily: "ClanOT-Black",
              textTransform: "uppercase",
              // opacity: 0.5,
              textAlign: "left",
              marginTop: 6,
            }}
          >
            +21
          </Text>
        </View>

        {/* {<Image
          style={{
            height: "110%",
            aspectRatio: 1,
            resizeMode: "contain",
            position: "absolute",
            bottom: -40,
            right: -120,
          }}
          source={{ uri: item.image }}
        />} */}

        <View
          style={[
            {
              width: "100%",
              height: 300,
              // marginTop: -24,
              // marginBottom:24,
              // marginRight:-padding,
              // marginLeft:-padding,
              // marginBottom: -padding,
              // backgroundColor:'white',
              // padding:16,
              // borderRadius:16,
              flexDirection: "row",
            },
          ]}
        >
          <View
            style={{
              width: 20,
              height: "100%",
              backgroundColor: "white",
              opacity: 0.5,
              paddingLeft: 6,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                height: "50%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontFamily: "ClanOT-Bold",
                  fontSize: 12,
                }}
              >
                J A B E U R
              </Text>
            </View>
            <View
              style={{
                height: "50%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontFamily: "ClanOT-Bold",
                  fontSize: 12,
                }}
              >
                G A U F F
              </Text>
            </View>
          </View>
          <View style={{ flex: 1, height: "100%" }}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={"100%"}
                height={"100%"}
                data={series}
                margin={
                  {
                    // top: 20,
                    // right: 30,
                    // left: 0,
                    // bottom: 20,
                  }
                }
              >
                <CartesianGrid strokeDasharray="2 2" />
                <defs>
                  <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={off} stopColor={green} stopOpacity={1} />
                    <stop offset={off} stopColor={purple} stopOpacity={1} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="url(#splitColor)"
                  fill="url(#splitColor)"
                />
                {/* {<Scatter dataKey="uv" fill='white' r={200} />} */}
              </ComposedChart>
            </ResponsiveContainer>
          </View>
        </View>
      </View>

      {
        <BrandLogo
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
          }}
        />
      }
    </View>
  );
};

const PlayerShotPerformance = ({}) => {
  const width = 400;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Jannik Sinner`,
    stat: `12%`,
    caption: `to win`,
    image: `https://photoresources.wtatennis.com/photo-resources/2024/04/22/b6184f99-e557-434c-a6a7-cba7d47bef27/Jabeur-Torso_316847.png?width=950`,
  };

  const series = [
    {
      subject: "Forehand",
      A: 91,
      B: 60,
      fullMark: 150,
    },
    {
      subject: "Backhand",
      A: 24,
      B: 62,
      fullMark: 150,
    },
    {
      subject: "Serve",
      A: 91,
      B: 76,
      fullMark: 150,
    },
    {
      subject: "Volley",
      A: 60,
      B: 80,
      fullMark: 150,
    },
    {
      subject: "Smash",
      A: 50,
      B: 55,
      fullMark: 150,
    },
    {
      subject: "Dropshot",
      A: 40,
      B: 20,
      fullMark: 150,
    },
  ];

  const gradientOffset = () => {
    const dataMax = Math.max(...series.map((i) => i.uv));
    const dataMin = Math.min(...series.map((i) => i.uv));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          // padding,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding,
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: accent,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 1,
                textTransform: "uppercase",
              }}
            >
              Shot Ratings
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
                marginTop: 6,
              }}
            >
              {item.title}
            </Text>
          </View>
        </View>

        <View
          style={[
            {
              width: "100%",
              marginTop: -50,
              marginBottom: 20,
              // height: 300,
            },
          ]}
        >
          <RadarChart
            outerRadius={120}
            width={width}
            height={400}
            data={series}
          >
            <PolarGrid />
            <PolarAngleAxis
              dataKey="subject"
              fontFamily="ClanOT-Medium"
              fontSize={13}
            />
            {/* {<PolarRadiusAxis angle={30} domain={[0, 100]} fontFamily="ClanOT-Medium" fontSize={12}/>} */}

            <Radar
              name="2025 Season"
              dataKey="B"
              stroke={green}
              fill={green}
              fillOpacity={0.6}
            />
            <Radar
              name="This Tournament"
              dataKey="A"
              stroke={orange}
              fill={orange}
              fillOpacity={0.6}
            />
            {/* {<Legend iconType='circle'  fontFamily="ClanOT-Bold"/>} */}
          </RadarChart>
          <View
            style={{ paddingLeft: 20, flexDirection: "row", marginTop: -20 }}
          >
            {[
              { color: green, text: "This Season" },
              { color: orange, text: "This Tournament" },
            ].map((obj) => (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 16,
                }}
              >
                <View
                  style={{
                    height: 8,
                    aspectRatio: 1,
                    borderRadius: 2,
                    backgroundColor: obj.color,
                    marginRight: 8,
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    color: "black",
                    fontFamily: "ClanOT-Medium",
                  }}
                >
                  {obj.text}
                </Text>
              </View>
            ))}
          </View>
        </View>
      </View>

      {
        <BrandLogo
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
          }}
        />
      }
    </View>
  );
};

const PlayerRallyPerformance = ({}) => {
  const width = 400;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Madison Keys`,
    stat: `12%`,
    caption: `to win`,
    image: `https://photoresources.wtatennis.com/photo-resources/2024/04/22/b6184f99-e557-434c-a6a7-cba7d47bef27/Jabeur-Torso_316847.png?width=950`,
  };

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          // padding,
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding,
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: accent,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 1,
                textTransform: "uppercase",
              }}
            >
              Rally Length Win Rate
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
                marginTop: 6,
              }}
            >
              {item.title}
            </Text>
          </View>
        </View>

        <View
          style={[
            {
              width: "100%",
              // marginTop: -50,
              marginBottom: 60,
              // height: 300,
            },
          ]}
        >
          <PlayerRallyLength id={data.focus_player} />
        </View>
      </View>

      {
        <BrandLogo
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
          }}
        />
      }
    </View>
  );
};

const RenderAreaChartAxes = ({}) => {
  const series = [
    {
      name: "Page A",
      uv: 104,
    },
    {
      name: "Page B",
      uv: 108,
    },
    {
      name: "Page C",
      uv: 84,
    },
    {
      name: "Page D",
      uv: 82,
    },
    {
      name: "Page E",
      uv: 63,
    },
    {
      name: "Page F",
      uv: 34,
    },
    {
      name: "Page G",
      uv: 32,
      highlight: 32,
    },
  ].map((obj) => {
    return {
      ...obj,
      uv: 1 / obj.uv,
    };
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={"100%"}
        height={60}
        data={series}
        margin={{
          // top: 5,
          // right: 20,
          left: 0,
          // bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={orange} stopOpacity={0.8} />
            <stop offset="95%" stopColor={orange} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="uv"
          stroke={orange}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Scatter dataKey="uv" fill={orange} r={200} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const RenderAreaChart = ({}) => {
  const series = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      highlight: 3490,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={"100%"}
        height={60}
        data={series}
        margin={{
          // top: 5,
          // right: 20,
          left: 0,
          // bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={orange} stopOpacity={0.8} />
            <stop offset="95%" stopColor={orange} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="uv"
          stroke={orange}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Scatter dataKey="uv" fill={orange} r={200} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const PathToTheTitle = ({}) => {
  const width = 400;
  const aspectRatio = 1.5;
  const padding = borderRadius / 1.5;

  const color = "black";
  const backgroundColor = "white";
  const accent = orange;
  const reducedOpacity = 0.5;

  const item = {
    header: `Title Chances`,
    title: `Paula Badosa`,
    stat: `12%`,
    caption: `to win`,
    image: `https://photoresources.wtatennis.com/photo-resources/2024/04/24/c425ac88-e1a7-4fec-92e5-edf64eeee6da/Badosa-Torso_320124.png?width=950`,
  };

  const results = [
    {
      opp: "Donna Vekic",
      seed: 8,
      round: "Quarterfinal",
      h2h: "Leads H2H 2-1",
      image: 'https://photoresources.wtatennis.com/photo-resources/2024/04/26/69cd52fd-4fbf-4682-8c89-540d3dcce359/Vekic-Torso_318516-WTA-Tennis.png?width=950',
    },
    {
      opp: "Coco Gauff",
      seed: 3,
      round: "Semifinal",
      h2h: "Have not met",
      image: 'https://photoresources.wtatennis.com/photo-resources/2024/04/24/c1c56486-b728-4d8d-b55a-9d64b654bc28/Gauff-Torso_328560.png?width=950',
    },
    {
      opp: "Aryna Sabalenka",
      seed: 1,
      round: "Final",
      h2h: "Trails H2H 2-8",
      image: 'https://photoresources.wtatennis.com/photo-resources/2024/04/22/13eae957-93d8-46a3-8c03-3b117f61ea0f/Sabalenka-Torso_320760.png?width=950',
    },
  ];

  return (
    <View
      style={[
        { width, borderRadius, backgroundColor, overflow: "hidden" },
        styles.widgetShadow,
      ]}
    >
      <BackgroundPattern tintColor={color} />
      <View
        style={{
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "center",
          padding,
        }}
      >
        <Text
          style={{
            color: orange,
            fontSize: 18,
            fontFamily: "ClanOT-Bold",
            opacity: 1,
            textTransform: "uppercase",
          }}
        >
          Projected Path to the title
        </Text>
        <Text
          style={{
            color,
            fontSize: 34,
            fontFamily: "ClanOT-Black",
            textTransform: "uppercase",
            opacity: 1,
            textAlign: "left",
            marginTop: 6,
          }}
        >
          {item.title}
        </Text>

        <Image
          style={{
            height: "100%",
            aspectRatio: 1,
            resizeMode: "contain",
            position: "absolute",
            bottom: -40,
            right: -120,
          }}
          source={{ uri: item.image }}
        />

        <View
          style={{
            // flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: 24,
          }}
        >
          {results.map((obj, i) => (
            <View
              style={[
                {
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor,
                  padding: 12,
                  paddingLeft:0,
                  paddingBottom:0,
                  borderRadius: 16,
                  marginTop: i ? 16 : 0,
                  overflow:'hidden',
                },
                styles.widgetShadowLite,
              ]}
            >
              <Image
                style={{
                  height: 80,
                  width: 80,
                  marginRight: 12,
                  borderRadius: 0,
                  // backgroundColor: "white",
                  resizeMode: "contain",
                  marginBottom:-4,
                }}
                source={{ uri: obj.image }}
              />
              <View style={{ alignItems: "flex-start", paddingBottom:12 }}>
                <Text
                  style={{
                    fontFamily: "ClanOT-News",
                    fontSize: 12,
                    color,
                    textAlign: "center",
                  }}
                >
                  {obj.round}
                </Text>
                <Text
                  style={{
                    fontFamily: "ClanOT-Medium",
                    fontSize: 15,
                    color,
                    textAlign: "center",
                    marginVertical: 4,
                  }}
                >
                  {obj.opp}
                </Text>
                <Text
                  style={{
                    fontFamily: "ClanOT-News",
                    fontSize: 12,
                    opacity: 0.5,
                    color,
                    textAlign: "center",
                  }}
                >
                  {obj.h2h}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>

      {
        <BrandLogo
          style={{
            position: "absolute",
            bottom: padding / 2,
            right: padding / 2,
            height: 48,
            aspectRatio: 1,
            resizeMode: "contain",
          }}
        />
      }
    </View>
  );
};

//---------
//PLAYER TABLE
const PlayerShotStrength = ({ id }) => {
  const player = data.players.find((obj) => obj.id === id);
  const player_stats = data.player_statistics.find((obj) => obj.id === id);
  if (!player) return null;

  const radarData = [
    {
      title: "Serving",
      score:
        (player_stats.service_points_won /
          (player_stats.service_points_won +
            player_stats.service_points_lost)) *
        100,
    },
    {
      title: "Returning",
      score: 36,
    },
    {
      title: "Net Play",
      score: 21,
    },
    {
      title: "Forehand",
      score: 85,
    },
    {
      title: "Backhand",
      score: 24,
    },

    {
      title: "X",
      score: 24,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="90%" data={radarData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="title" />
        <PolarRadiusAxis />
        <Radar
          name="Player"
          dataKey="score"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const PlayerRallyLength = ({ id }) => {
  const player = data.players.find((obj) => obj.id === id);
  const player_stats = data.player_statistics.find((obj) => obj.id === id);
  if (!player) return null;

  const radarData = [
    {
      title: "0-3",
      value:
        (player.rallyStats_ralliesWonUnder3Shots /
          player.rallyStats_ralliesUnder3Shots) *
        100,
    },
    {
      title: "4-6",
      value:
        (player.rallyStats_ralliesWonOver3Shots /
          player.rallyStats_ralliesOver3Shots) *
        100,
    },
    {
      title: "7-9",
      value:
        (player.rallyStats_ralliesWonOver6Shots /
          player.rallyStats_ralliesOver6Shots) *
        100,
    },
    {
      title: "10-12",
      value:
        (player.rallyStats_ralliesWonOver9Shots /
          player.rallyStats_ralliesOver9Shots) *
        100,
    },
    {
      title: "12+",
      value:
        (player.rallyStats_ralliesWonOver12Shots /
          player.rallyStats_ralliesOver12Shots) *
        100,
    },
  ];

  return (
    <StatBarChart
      series={radarData}
      keys={["value"]}
      background={{ fill: "#eee" }}
    />
  );
};

const PlayerWinnerShots = ({ id }) => {
  const player = data.players.find((obj) => obj.id === id);
  const player_stats = data.player_statistics.find((obj) => obj.id === id);
  if (!player) return null;

  const radarData = ["Total", "Forehand", "Backhand", "Net", "Service"].map(
    (key) => {
      return {
        title: key,
        winners: player[`winnerStats_${key.toLowerCase()}Winners`],
        errors: player[`errorStats_${key.toLowerCase()}UnforcedErrors`],
      };
    }
  );

  return (
    <StatBarChart
      series={radarData}
      keys={["winners", "errors"]}
      colors={["green", "red"]}
    />
  );
};

const StatBarChart = ({ series, keys, background, colors }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={"100%"}
        height={300}
        data={series}
        margin={{
          // top: 5,
          right: 20,
          left: -20,
          // bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 2" />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={orange} stopOpacity={0.8} />
            <stop offset="95%" stopColor={orange} stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <XAxis
          stroke="rgba(0,0,0,0.5"
          dataKey="title"
          fontSize={12}
          fontFamily="ClanOT-Bold"
        />
        {<YAxis domain={[0, 100]} fontSize={12} fontFamily="ClanOT-Medium" />}
        {keys.map((key, i) => (
          <Bar
            dataKey={key}
            // fill={orange}
            fill="url(#colorUv)"
            // background={background}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const PlayerServingTable = ({ id }) => {
  const player = data.players.find((obj) => obj.id === id);
  const keys = [
    { key: "stat", title: "Stat" },
    { key: "value", title: "Value" },
  ];

  const statKeys = [
    { title: "Serves into the net", value: "serveFaults_net" },
    { title: "Serves hit out", value: "serveFaults_out" },
    { title: "Foot faults", value: "" },
    { title: "", value: "" },
    { title: "", value: "" },
    { title: "", value: "" },
    { title: "", value: "" },
    { title: "", value: "" },
    { title: "", value: "" },
  ];
  const stats = statKeys.map((key) => {
    return {
      stat: key.title,
      value: player[key.value],
    };
  });

  return (
    <StatsTable
      hideNumber
      stats={stats}
      num={10}
      playerHeader={player}
      keys={keys}
    />
  );
};

//

const SPWTable = () => {
  const players = data.player_statistics.map((obj) => {
    return {
      ...obj,
      spw:
        Math.round(
          (obj.service_points_won /
            (obj.service_points_won + obj.service_points_lost)) *
            1000
        ) / 10,
    };
  });
  const stats = players.sort((a, b) => (a.spw > b.spw ? -1 : 1));

  const keys = [{ key: "spw", title: "SPW %" }];
  return (
    <StatsTable title="Service Points Won Leader" stats={stats} keys={keys} />
  );
};

const GWTable = () => {
  const players = data.player_statistics.map((obj) => {
    return {
      ...obj,
      gw:
        Math.round((obj.games_won / (obj.games_won + obj.games_lost)) * 1000) /
        10,
    };
  });
  const stats = players.sort((a, b) => (a.gw > b.gw ? -1 : 1));

  const keys = [{ key: "gw", title: "GW %" }];
  return <StatsTable title="Games Won Leader" stats={stats} keys={keys} />;
};

const BPTable = () => {
  const players = data.player_statistics.map((obj) => {
    return {
      ...obj,
      gw: Math.round((obj.breakpoints_won / obj.total_breakpoints) * 1000) / 10,
    };
  });
  const stats = players.sort((a, b) => (a.gw > b.gw ? -1 : 1));

  const keys = [
    { key: "breakpoints_won", title: "Won" },
    { key: "total_breakpoints", title: "Total" },
    { key: "gw", title: "Win %" },
  ];
  return (
    <StatsTable title="Break Points Won Leader" stats={stats} keys={keys} />
  );
};

const PerformanceTable = () => {
  const players = data.player_statistics.map((obj) => {
    return {
      ...obj,
      gw: Math.round((obj.service_points_won / obj.service_points_lost) * 100),
    };
  });
  const stats = players.sort((a, b) => (a.gw > b.gw ? -1 : 1));

  const keys = [
    // { key: "breakpoints_won", title: "Won" },
    // { key: "total_breakpoints", title: "Total" },
    { key: "gw", title: "Rating" },
  ];
  return <StatsTable title="Performance Leaders" stats={stats} keys={keys} />;
};

const AcesTable = () => {
  const players = data.player_statistics;
  const stats = players.filter(obj => obj.id !== 'sr:competitor:128584').sort((a, b) => (a.aces > b.aces ? -1 : 1));

  const keys = [
    { key: "aces", title: "Aces" },
    // { key: "matches", title: "Matches" },
  ];
  return (
    <StatsTable
      title="Ace Leader"
      // stat='Aces'
      mode="players"
      stats={stats}
      num={10}
      showLeader
      keys={keys}
    />
  );
};

const LongRallyTable = () => {
  const players = data.players
    .filter((obj) => obj.rallyStats_averageRallyLength && !obj.name.includes('Kartal'))
    .map((obj) => {
      return {
        ...obj,
        rallyStats_averageRallyLength:
          Math.round(obj.rallyStats_averageRallyLength * 10) / 10,
      };
    });
  const stats = players.sort((a, b) =>
    a.rallyStats_averageRallyLength > b.rallyStats_averageRallyLength ? -1 : 1
  );

  const keys = [
    { key: "rallyStats_averageRallyLength", title: "Avg. Length" },
    { key: "rallyStats_longestRally", title: "Longest" },
  ];
  return (
    <StatsTable title="Long Rally Champions" mode="players" stats={stats} num={10} showLeader keys={keys} />
  );
};

const AvgWinnersTable = () => {
  const players = data.players
    .filter((obj) => obj.winnerStats_totalWinners)
    .map((obj) => {
      return {
        ...obj,
        avg:
          Math.round(
            (obj.winnerStats_totalWinners / obj.rallyStats_numberOfRallies) *
              100
          ) / 100,
      };
    });
  const stats = players.sort((a, b) => (a.avg > b.avg ? -1 : 1));

  const keys = [{ key: "avg", title: "Avg. Winners / Point" }];
  return (
    <StatsTable title="Avg. Winners Per Point" mode="players" stats={stats} num={10} showLeader keys={keys} />
  );
};

const AvgErrorsTable = () => {
  const players = data.players
    .filter((obj) => obj.errorStats_totalUnforcedErrors)
    .map((obj) => {
      return {
        ...obj,
        avg:
          Math.round(
            (obj.errorStats_totalUnforcedErrors /
              obj.rallyStats_numberOfRallies) *
              100
          ) / 100,
      };
    });
  const stats = players.sort((a, b) => (a.avg > b.avg ? -1 : 1));

  const keys = [{ key: "avg", title: "Avg. Errors / Point" }];
  return (
    <StatsTable mode="players" stats={stats} num={10} showLeader keys={keys} />
  );
};

const ViolationsTable = () => {
  const players = data.players;
  const stats = players.sort((a, b) =>
    a.violationsPenalties_timeViolations > b.violationsPenalties_timeViolations
      ? -1
      : 1
  );

  const keys = [
    { key: "violationsPenalties_timeViolations", title: "Time Violations" },
    // { key: "rallyStats_longestRally", title: "Longest" }
  ];
  return (
    <StatsTable mode="players" stats={stats} num={10} showLeader keys={keys} />
  );
};

const MatchesDurationTable = ({ reverse }) => {
  const matches = data.matches
    .filter((obj) => obj.finishedAt && obj.winner_id)
    .map((obj) => {
      const comp = obj.competitors.find((p) => p.id === obj.winner_id);
      return {
        ...comp,
        // flag:getAlpha2Code(comp.country_code, "en"),
        subtitle: `${obj.round} vs ${
          obj.competitors
            .find((p) => p.id !== obj.winner_id)
            .name.split(", ")[0]
        }`,
        time: Math.round(
          (obj.finishedAt - Number(obj.start_time_timestamp)) / 1000 / 60
        ),
      };
    });
  const stats = matches.sort(
    (a, b) => (a.time < b.time ? -1 : 1) * (reverse ? -1 : 1)
  );

  const keys = [
    // { key: "player", title: "Player" },
    // { key: "round", title: "Round" },
    { key: "time", title: "Duration (min)" },
    // { key: "rallyStats_longestRally", title: "Longest" }
  ];
  return (
    <StatsTable
      title="Fastest Matches"
      hideNumber
      mode="players"
      stats={stats}
      num={10}
      keys={keys}
    />
  );
};

const HawkEyeTable = () => {
  const players = data.players
    .filter((obj) => obj.hawkeyeChallenges_total)
    .map((obj) => {
      return {
        ...obj,
        hawkeyeChallenges_accuracy: Math.round(
          (obj.hawkeyeChallenges_successful / obj.hawkeyeChallenges_total) * 100
        ),
        hawkeyeChallenges_accuracyPerc:
          Math.round(
            (obj.hawkeyeChallenges_successful / obj.hawkeyeChallenges_total) *
              100
          ) + "%",
      };
    });
  const stats = players.sort((a, b) =>
    a.hawkeyeChallenges_accuracy > b.hawkeyeChallenges_accuracy ? -1 : 1
  );

  const keys = [
    { key: "hawkeyeChallenges_successful", title: "Correct" },
    { key: "hawkeyeChallenges_unsuccessful", title: "Wrong" },
    { key: "hawkeyeChallenges_accuracyPerc", title: "Accuracy" },
  ];
  return (
    <StatsTable title="Hawkeye Specialists" mode="players" stats={stats} num={10} showLeader keys={keys} />
  );
};

const TopSeeds = ({ tracking }) => {
  const players = data.players.filter(
    (obj) => obj.stage !== "qualification" && Number.isInteger(obj.seed)
  );
  const stats = players.sort((a, b) => (a.seed < b.seed ? -1 : 1));

  const keys = tracking
    ? [{ key: "status", title: "Status" }]
    : [
        // { key: "seed", title: "Seed" },
        { key: "rank", title: "Ranking" },
      ];
  return (
    <StatsTable
      stats={stats}
      num={10}
      showLeader
      leaderHeadline={tracking ? stats[0].status : "1st Seed"}
      keys={keys}
      mode="players"
      // showElimination={tracking}
      // hideNumber={!tracking}
    />
  );
};

const StatsTable = ({
  stats,
  num,
  showLeader,
  leaderHeadline,
  title,
  keys,
  showElimination,
  hideNumber,
  mode,
}) => {
  const padding = 20;
  const item = stats[0];
  const accent = orange;
  const color = "black";
  const reducedOpacity = 0.5;

  return (
    <View
      style={[
        {
          width: 500,
          borderRadius: 24,
          padding: 24,
          paddingBottom: 8,
          backgroundColor: "white",
          overflow: "hidden",
        },
        styles.widgetShadow,
      ]}
    >
      <View style={{ width: "100%" }}>
        <View
          style={{
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: accent,
              fontSize: 18,
              fontFamily: "ClanOT-Bold",
              opacity: 1,
              textTransform: "uppercase",
            }}
          >
            {title}
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
            }}
          >
            <Text
              style={{
                color,
                fontSize: 24,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
              }}
            >
              {item.name.split(", ").reverse().join(" ")}
            </Text>

            <View style={{ marginBottom: 1, marginLeft: 12 }}>
              <Flag code={item.flag} size={28} />
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: 16,
            }}
          >
            {keys.map((key, k) => (
              <View style={{ marginRight: 12, padding:8, paddingHorizontal:16, borderRadius:8, borderWidth:0.5, borderColor:'rgba(0,0,0,0.05)' }}>
                <Text
                  style={{
                    color: accent,
                    fontSize: 32,
                    fontFamily: "ClanOT-Black",
                    opacity: 1,
                    textAlign: "left",
                  }}
                >
                  {item[key.key]}
                </Text>
                {
                  <Text
                    style={{
                      color,
                      fontSize: 14,
                      fontFamily: "ClanOT-Medium",
                      opacity: reducedOpacity || 0.5,
                      marginTop: 0,
                    }}
                  >
                    {key.title}
                  </Text>
                }
              </View>
            ))}
          </View>
        </View>

        <Image
          style={{
            height: "120%",
            aspectRatio: 1,
            resizeMode: "contain",
            position: "absolute",
            bottom: -18,
            right: -30,
          }}
          source={{ uri: data.photos[item.id] || item.photo || item.image || defaultImage }}
        />
      </View>
      <View
        style={{
          width: "100%",
          opacity: 0.5,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingVertical: 12,
          marginTop: 12,
          borderTopWidth: 1,
          borderTopColor: "rgba(0,0,0,0.05)",
        }}
      >
        <Text style={{ fontSize: 13, fontFamily: "ClanOT-Medium", width: 30 }}>
          #
        </Text>
        <Text style={{ fontSize: 13, fontFamily: "ClanOT-Medium", flex: 1 }}>
          Player
        </Text>
        {keys.map((key) => {
          return (
            <Text
              style={{
                width: 80,
                textAlign: "right",
                fontSize: 13,
                fontFamily: "ClanOT-Medium",
              }}
            >
              {key.title}
            </Text>
          );
        })}
      </View>
      {stats.slice(1, 5).map((p, i) => {
        return (
          <View
            style={{
              width: "100%",
              paddingVertical: 12,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                width: 30,
                textAlign: "left",
                fontSize: 16,
                opacity: 0.5,
                fontFamily: "ClanOT-Medium",
              }}
            >
              {i + 2}
            </Text>
            <RenderPlayer {...p} />
            {keys.map((key) => {
              return (
                <Text
                  style={{
                    width: 80,
                    textAlign: "right",
                    fontSize: 16,
                    fontFamily: "ClanOT-Book",
                  }}
                >
                  {p[key.key]}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

const RenderPlayer = ({ id, name, flag, seed, subtitle }) => {
  if (!flag) {
    const player = data.players.find((obj) => obj.id === id);
    if (player && player.flag) flag = player.flag;
  }
  return (
    <View
      style={{
        flex: 1,
        // flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Flag code={flag} size={20} />
        <Text
          style={{
            marginLeft: 8,
            fontSize: 16,
            color: "black",
            fontFamily: "ClanOT-Medium",
          }}
        >
          {name.split(", ").reverse().join(" ")}
        </Text>
        {
          seed &&
          <Text
          style={{
            marginLeft: 8,
            fontSize: 14,
            marginTop:1,
            color: "black",
            fontFamily: "ClanOT-News",
            opacity:0.35
          }}
        >
          {seed}
        </Text>
        }
      </View>
      {subtitle && (
        <Text
          style={{
            marginTop: 4,
            fontSize: 12,
            color: "black",
            fontFamily: "ClanOT-News",
            opacity: 0.5,
          }}
        >
          {subtitle}
        </Text>
      )}
    </View>
  );
};

const ProjectedFinals = ({}) => {
  const padding = 20;
  // const item = stats[0];
  const accent = orange;
  const color = "black";
  const reducedOpacity = 0.5;

  const projections = data.draw_projections;
  const final = projections[0][0];
  let item;
  if (final.p1.rank < final.p2.rank) {
    item = final.p1;
  } else {
    item = final.p2;
  }

  return (
    <View
      style={[
        {
          width: 400,
          borderRadius: 24,
          padding: 24,
          // paddingBottom: 8,
          backgroundColor: "white",
          overflow: "hidden",
        },
        styles.widgetShadow,
      ]}
    >
      <View style={{ width: "100%" }}>
        <View
          style={{
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: accent,
              fontSize: 18,
              fontFamily: "ClanOT-Bold",
              opacity: 1,
              textTransform: "uppercase",
            }}
          >
            Live Projections
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 6,
            }}
          >
            <Text
              style={{
                color,
                fontSize: 24,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
              }}
            >
              {item.name.split(", ").reverse().join(" ")}
            </Text>

            <View style={{ marginBottom: 1, marginLeft: 12 }}>
              <Flag code={item.flag} size={28} />
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: 0,
            }}
          >
            <Text
              style={{
                color,
                fontSize: 14,
                fontFamily: "ClanOT-Medium",
                opacity: reducedOpacity || 0.5,
                marginTop: 0,
              }}
            >
              Projected Champion
            </Text>
          </View>
        </View>

        <Image
          style={{
            height: "140%",
            aspectRatio: 1,
            resizeMode: "contain",
            position: "absolute",
            bottom: -20,
            right: -16,
          }}
          source={{ uri: item.image || defaultImage }}
        />
      </View>
      <View style={{width:'100%', borderTopWidth:1, borderTopColor:'rgba(0,0,0,0.05)', marginTop:16}}>
      {projections.map((round, j) => (
        <View style={{ width: "100%", marginTop: 24 }}>
          <Text style={{ fontSize: 15, fontFamily: "ClanOT-Medium", textTransform:'uppercase', opacity:0.75 }}>
            {
              {
                0: "Final",
                1: "Semifinals",
                2: "Quarterfinals",
              }[j]
            }
          </Text>
          {round.map((match, i) => (
            <View style={[{ width: "100%", padding:12, borderRadius:10, borderWidth:0.5, borderColor:'rgba(0,0,0,0.07)', marginTop:12 }]}>
              <RenderPlayer {...match.p1}/>
              <View style={{height:6}}/>
              <RenderPlayer {...match.p2}/>
            </View>
          ))}
        </View>
      ))}
      </View>
    </View>
  );
};

const StatsTableOld = ({
  stats,
  num,
  showLeader,
  leaderHeadline,
  keys,
  showElimination,
  hideNumber,
  mode,
}) => {
  const color = "black";
  const padding = 20;
  const leader = showLeader && stats[0];
  const rows = (stats && stats.slice(showLeader ? 0 : 0, 5)) || [];
  return (
    <View
      style={[
        {
          width: 400,
          borderRadius: 16,
          backgroundColor: "white",
          overflow: "hidden",
        },
        styles.widgetShadow,
      ]}
    >
      {leader && (
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding,
          }}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: orange,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 1,
                textTransform: "uppercase",
              }}
            >
              {leaderHeadline || "Stat Leader"}
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "left",
                marginTop: 6,
              }}
            >
              {leader.name.split(", ").reverse().join(" ")}
            </Text>
          </View>
          <View
            style={{
              // flex: 1,
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Text
              style={{
                color,
                fontSize: 18,
                fontFamily: "ClanOT-Bold",
                opacity: 0.5,
                textTransform: "uppercase",
              }}
            >
              Rank
            </Text>
            <Text
              style={{
                color,
                fontSize: 34,
                fontFamily: "ClanOT-Black",
                textTransform: "uppercase",
                opacity: 1,
                textAlign: "right",
                marginTop: 6,
              }}
            >
              32
            </Text>
          </View>
        </View>
      )}
      {leader && (
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 6,
            marginBottom: 6,
            borderWidth: 0.5,
            borderColor: "rgba(0,0,0,0.05)",
            borderRadius: 12,
            overflow: "hidden",
          }}
        >
          <View
            style={{
              height: 100,
              // width: 80,
              aspectRatio: 1,
              backgroundColor: "rgba(0,0,0,0.1)",
              // borderRadius: 6,
              // overflow:'hidden'
            }}
          >
            <Image
              style={{
                height: "100%",
                aspectRatio: 1,
                resizeMode: "cover",
                marginBottom: 6,
              }}
              source={{
                uri:
                  leader.photo ||
                  "https://photoresources.wtatennis.com/photo-resources/2020/09/30/93e089eb-564d-4d03-a601-4601eb2d4044/Vondrousova_Hero-Smile.png?height=1200",
              }}
            />
            <View style={{ position: "absolute", bottom: 0, right: 0 }}>
              <Flag code={leader.flag} size={24} />
            </View>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: 16,
            }}
          >
            <Text
              style={{
                fontSize: 24,
                color: "black",
                fontWeight: "600",
                width: "100%",
                textAlign: "left",
              }}
            >
              {leader.name.split(", ").reverse().join(" ")}
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              {keys.map((h, j) => (
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginRight: 24,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 17,
                      color: "black",
                      fontWeight: "500",
                      width: "100%",
                      textAlign: "left",
                      marginTop: 4,
                      opacity: 1,
                    }}
                  >
                    {leader[h.key]}
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      color: "black",
                      fontWeight: "500",
                      width: "100%",
                      textAlign: "left",
                      marginTop: 4,
                      opacity: 0.5,
                    }}
                  >
                    {h.title}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </View>
      )}
      <Table sx={{ "& tr > *:not(:first-child)": { textAlign: "right" } }}>
        <thead>
          <tr>
            {!hideNumber && <th style={{ width: 32 }}>#</th>}
            {mode === "players" && (
              <th style={{ width: "40%", textAlign: "left" }}>Player</th>
            )}
            {keys.map((h, j) => (
              <th>{h.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={row.name}>
              {!hideNumber && (
                <td style={{ width: 32 }}>{i + (leader ? 1 : 1)}</td>
              )}
              {mode === "players" && (
                <td style={{ width: "40%", textAlign: "left" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <Flag code={row.flag} />
                    <View
                      style={{
                        flex: 1,
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        paddingLeft: 12,
                      }}
                    >
                      <Text>
                        {row.name.split(", ").reverse().join(" ")}{" "}
                        {/* {row.seed && <Text style={{opacity:0.5}}> {row.seed}</Text>} */}
                      </Text>
                      {showElimination && row.eliminated && (
                        <Text
                          style={{ opacity: 0.5, fontSize: 13, marginTop: 2 }}
                        >
                          Eliminated {row.eliminated}
                        </Text>
                      )}
                    </View>
                  </View>
                </td>
              )}
              {keys.map((h, j) => (
                <td>
                  {typeof row[h.key] === "object" ? (
                    <View></View>
                  ) : (
                    <Text>{row[h.key]}</Text>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </View>
  );
};

const Flag = ({ code, size }) => {
  if (!code) return null;
  const width = size || 20;
  const height = width / 1.5;
  return (
    <Image
      source={{ uri: `https://flagcdn.com/h20/${code.toLowerCase()}.png` }}
      style={{
        height,
        width,
        resizeMode: "cover",
        borderRadius: width / 8,
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
    />
  );
  // return (
  //   <ReactCountryFlag
  //   countryCode={'NZ'}
  //   svg
  //   style={{width:20, height:10}}
  //   />
  // )
};

const borderColor = "rgba(0,0,0,0.1)";

const StatsPlayer = ({ item, index }) => {
  return (
    <View
      style={{
        width: "100%",
        paddingVertical: 12,
        borderTopWidth: 1,
        borderTopColor: borderColor,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Text style={{ fontSize: 13, color: "black" }}>{item.name.trim()}</Text>
    </View>
  );
};

const data = require("./widgets/data.json");
const Dashboard = () => {
  const [unlocked, setUnlocked] = React.useState(localStorage.getItem('miamiDemo'))

  useEffect(()=>{
    if(!unlocked){
      const res = prompt('Enter the password')
      if(res === 'miamiopen'){
        setUnlocked(true)
        localStorage.setItem('miamiDemo', true)
      }
    }
  },[])

  if(!unlocked) return null

  // Define widget groups/sections by category
  const widgetSections = [
    {
      // hide: true,
      sectionTitle: "Player",
      widgets: [
        {
          title: "KPI",
          render: (
            <KPI
              {...{
                item: {
                  header: `Match Fan Vote`,
                  title: `Ben\nShelton`,
                  // brand: true,
                  stat: `84%`,
                  caption: `to win`,
                  image: `https://www.atptour.com/-/media/alias/player-gladiator/S0S1`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "KPI Accent",
          render: (
            <KPI
              {...{
                backgroundColor: orange,
                color: "white",
                accent: "white",
                reducedOpacity: 0.67,
                item: {
                  header: `Live Ranking`,
                  title: `Coco\nGauff`,
                  stat: `3`,
                  caption: `+2 spots`,
                  image: `https://photoresources.wtatennis.com/photo-resources/2024/04/24/c1c56486-b728-4d8d-b55a-9d64b654bc28/Gauff-Torso_328560.png?width=950`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "KPI Branded",
          render: (
            <KPI
              {...{
                item: {
                  header: `Live Predictor`,
                  title: `Carlos\nAlcaraz`,
                  stat: `26%`,
                  brand: true,
                  caption: `to win the title`,
                  image: `https://www.nittoatpfinals.com/-/media/alias/player-gladiator/A0E2`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "Path To The Title",
          render: <PathToTheTitle />,
          width: null,
        },
        {
          title: "Ranking History",
          render: <PlayerRankingTimeline />,
          // width: ,
        },
        // {
        //   title: "Match Form",
        //   render: <PlayerForm />,
        //   width: null,
        // },
        // {
        //   title: "Tournament Form",
        //   render: <PlayerTournamentForm />,
        //   width: null,
        // },
        {
          title: "Shot Performance",
          render: <PlayerShotPerformance />,
        },
        {
          title: "Rally Length Performance",
          render: <PlayerRallyPerformance />,
        },
      ],
    },
    {
      sectionTitle: "Match - Fan Voting & Predictions",
      widgets: [
        {
          title: "Vote",
          render: (
            <KPI
              {...{
                item: {
                  header: `Match Fan Vote`,
                  title: `Danielle\nCollins`,
                  // brand: true,
                  stat: `84%`,
                  caption: `to win`,
                  image: `https://photoresources.wtatennis.com/photo-resources/2024/04/29/f5d54ab9-fa99-4cce-ba02-5c177c87b385/Collins-Torso_316925-WTA-Tennis.png?width=950`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "Pre-Match Predictor",
          render: (
            <KPI
              {...{
                item: {
                  header: `Win Predictor`,
                  title: `Reilly\nOpelka`,
                  // brand: true,
                  stat: `71%`,
                  caption: `to win`,
                  image: `https://www.atlantaopentennis.com/-/media/alias/player-gladiator/o522`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "Match of the Day",
          render: (
            <KPI
              {...{
                item: {
                  header: `Match Of The Day`,
                  title: `Naomi\nOsaka`,
                  brand: true,
                  stat: `42%`,
                  caption: `of fans`,
                  image: `https://photoresources.wtatennis.com/photo-resources/2024/04/26/c0aafbd2-cead-4109-9bc5-f6c30984c189/Osaka-Torso_319998.png?width=950`,
                },
              }}
            />
          ),
          width: null,
        },
      ],
    },
    {
      sectionTitle: "Match Analysis & Insights",
      widgets: [
        {
          title: "Momentum",
          render: <MatchMomentum />,
          width: null,
        },
        {
          title: "Live Win Predictor",
          render: <PlayerWinPredictor />,
          width: null,
        },
      ],
    },
    // {
    //   sectionTitle: "Daily Picks",
    //   widgets: [
    //     {
    //       title: "Fan Picks to Win",
    //       render: null,
    //       width: null,
    //     },
    //   ],
    // },
    {
      sectionTitle: "Tournament & Daily Stats",
      widgets: [
        {
          title: "Stat Leader",
          render: (
            <KPI
              {...{
                item: {
                  header: `Aces Leader`,
                  title: `Nick\nKyrgios`,
                  stat: `12`,
                  caption: `per set`,
                  image: `https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/929746a0-24b4-4de3-bfb4-fc035337f53f/dej4xx9-fcb0c33b-dcb9-4d31-bf3d-57260957d245.png/v1/fill/w_894,h_894/nick_kyrgios_render__1_by_tennispng_dej4xx9-pre.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NDQ3MiIsInBhdGgiOiJcL2ZcLzkyOTc0NmEwLTI0YjQtNGRlMy1iZmI0LWZjMDM1MzM3ZjUzZlwvZGVqNHh4OS1mY2IwYzMzYi1kY2I5LTRkMzEtYmYzZC01NzI2MDk1N2QyNDUucG5nIiwid2lkdGgiOiI8PTQ0NzIifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6aW1hZ2Uub3BlcmF0aW9ucyJdfQ.ND_irH-qKrlvbLdIZoSSYeh7WRfDPMi0fC_wWUCnCEo`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "Stat Leader - Accent",
          render: (
            <KPI
              {...{
                backgroundColor: orange,
                color: "white",
                accent: "white",
                item: {
                  header: `Fastest Set`,
                  title: `Iga\nSwiatek`,
                  stat: `12`,
                  // brand:true,
                  caption: `minutes`,
                  image: `https://photoresources.wtatennis.com/photo-resources/2024/04/22/2742b3c7-dc87-47bc-bd76-6f23fbd3b45c/Swiatek-Torso_326408.png?width=950`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "Stat Leader - Branded",
          render: (
            <KPI
              {...{
                // backgroundColor: orange,
                // color: "white",
                // accent: "white",
                item: {
                  header: `Longest Rally`,
                  title: `Taylor\nFritz`,
                  stat: `64`,
                  brand:true,
                  caption: `shots`,
                  image: `https://www.atptour.com/-/media/alias/player-gladiator/FB98`,
                },
              }}
            />
          ),
          width: null,
        },
        {
          title: "Performance vs Expectations",
          render: <PerformanceTable />,
          width: null,
        },
        {
          title: "Ace %",
          render: <AcesTable />,
          width: null,
        },
        {
          title: "Winners %",
          render: <AvgWinnersTable />,
          width: null,
        },
        {
          title: "Long Rally Champions",
          render: <LongRallyTable />,
          width: null,
        },
        {
          title: "Hawkeye Pros",
          render: <HawkEyeTable />,
          width: null,
        },
        {
          title: "Breakpoints",
          render: <BPTable />,
          width: null,
        },
        {
          title: "Service Points Won",
          render: <SPWTable />,
          width: null,
        },
        {
          title: "Games Won",
          render: <GWTable />,
          width: null,
        },
        {
          title: "Fastest Matches",
          render: <MatchesDurationTable />,
          width: null,
        },
      ],
    },
    {
      hide:true,
      sectionTitle: "Projections",
      widgets: [
        {
          title: "Projected Finals",
          render: <ProjectedFinals />,
          width: null,
        },
      ],
    },
  ].filter((obj) => !obj.hide);

  return (
    <>
    <View style={{flexDirection:'row', width:'100%', alignItems:'center', borderBottomWidth:1, borderBottomColor:'rgba(0,0,0,0.05)', paddingVertical:12, backgroundColor:'white', paddingHorizontal:48}}>
      <Image
      style={{height:80, aspectRatio:496/335, resizeMode:'contain'}}
      source={{uri:'https://totallympics.com/uploads/monthly_2024_03/cropped-Favicon2.png.7d18cf28e9455133ced7f2bdb6b3bdd1.png'}}
      />
      <Text style={{color:'black', fontFamily:'ClanOT-Medium', fontSize:17, flex:1, textAlign:'right', opacity:0.5}}>
        Widget Catalogue
        </Text>
    </View>
    <ScrollView contentContainerStyle={styles.container}>
      {widgetSections.map((section, idx) => (
        <WidgetSection
          key={idx}
          sectionTitle={section.sectionTitle}
          widgets={section.widgets}
        />
      ))}
    </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    // padding: 48,
    backgroundColor: "#f2f3f5",
    flexGrow: 1,
    alignItems: "center",
  },
  pageTitle: {
    fontSize: 36,
    fontWeight: "bold",
    // marginVertical: 10,
    textAlign: "center",
  },
  pageSubtitle: {
    fontSize: 18,
    opacity: 0.5,
    fontWeight: "medium",
    // marginVertical: 10,
    marginTop: 8,
    marginBottom: 48,
    textAlign: "center",
  },
  section: {
    marginBottom: 60,
    width:'100%'
  },
  sectionTitle: {
    fontSize: 17,
    fontFamily:'ClanOT-Bold',
    // marginBottom: 10,
    color: "#000",
    width: "100%",
    textAlign: "center",
    textTransform:'uppercase'
  },
  sectionGrid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  widgetCard: {
    // backgroundColor: "white",
    // borderRadius: 12,
    // padding: 24,
    margin: 48,
    // width: 400,
    // Shadow for iOS and elevation for Android (react-native-web supports similar styling)
    // shadowColor: "#000",
    // shadowOffset: { width: 0, height: 10 },
    // shadowOpacity: 0.1,
    // shadowRadius: 40,
    // elevation: 10,
  },
  widgetHeader: {
    // borderBottomWidth: 1,
    // borderBottomColor: '#ddd',
    // paddingBottom: 5,
    // marginBottom: 5
  },
  widgetShadow: {
    //  Shadow for iOS and elevation for Android (react-native-web supports similar styling)
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.1,
    shadowRadius: 40,
    elevation: 10,
  },
  widgetShadowLite: {
    //  Shadow for iOS and elevation for Android (react-native-web supports similar styling)
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.05,
    shadowRadius: 15,
    elevation: 5,
  },
  widgetTitle: {
    fontSize: 17,
    fontFamily: "ClanOT-Medium",
    color: "#000",
    width: "100%",
    textAlign: "center",
    marginBottom: 24,
    opacity:0.5
  },
  widgetDescription: {
    fontSize: 14,
    marginBottom: 12,
    marginTop: 6,
    color: "#555",
    width: "100%",
    textAlign: "center",
  },
  placeholder: {
    backgroundColor: "#eaeaea",
    borderRadius: 5,
    height: 300, // simulate a fixed aspect ratio container
    justifyContent: "center",
    alignItems: "center",
  },
  placeholderText: {
    color: "#888",
    fontSize: 16,
  },
  branding: {
    marginTop: 12,
    alignItems: "center",
    // borderTopWidth: 1,
    borderTopColor: "#ddd",
    // paddingTop: 5,
  },
  brandingText: {
    fontSize: 12,
    color: "#999",
  },
});

export default Dashboard;
