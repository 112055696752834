/* @flow */

import React, { PureComponent } from 'react';
import {
  View,
  Text,
  Button,
  Image
} from 'react-native';

//Modules
import { Link } from '@react-navigation/native';
import { Helmet } from "react-helmet";

import { NavigationContainer } from '@react-navigation/native';
// import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

//Components
import Tabs from '../components/general/tabs.js'
import Background from '../components/general/background.js'
import Loading from '../components/general/loading.js'
import SEOTitle from '../components/general/seotitle.js'
import Twitter from '../components/general/twitter.js'
import Form from './player/form.js'
import BottomNavbar from '../components/navigation/bottomnavbar.js'
import Matches from './tournament/matches.js'
import Draws from './tournament/draws.js'
import Info from './player/info.js'
import Players from './tournament/players.js'

import Header from '../components/seo/header.js'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
  isIOS,
} from "react-device-detect";

//Helpers
import MainStyles from '../helpers/mainstyles.js'
import GLOBAL from '../helpers/global.js'
import Functions from '../helpers/functions.js'

export default class MyComponent extends PureComponent {
  //Setup
  constructor(props){
    super(props);
    this.state = {
      loading:true,
      active_tab:'Live',
      id:this.props.route?.params?.id,
    }
  }

  componentDidMount = async () => {
    this.setup()
  }

  componentWillUnmount = async () => {

  }

  setup = async () => {
    var { id } = this.state
    var params = {
      id,
      mode:'player',
    }

    //Fetch API
    var data_r = await Functions.tnnsAPI(params)
    var data_obj = data_r?.data || {}
    var { tabs, tabs_data, header, seo } = data_obj

    //For the form screen
    var players = []

    //Set the active_tab
    var active_tab = tabs[0]?.id
    var active_tab_data = tabs[0]

    var title = seo.name + " - Tennis Results, Bio, Rankings, Win-Loss, Performance & More"
    this.props.navigation.setOptions({title})

    var description = seo.name+" player profile, results, bio, rankings, win loss, performance and more."

    this.setState({title, tabs, description, active_tab, active_tab_data, tabs_data, header, players, loading:false})


  }



  renderContent = () => {
    var { loading, description } = this.state
    // var title = "Live Tennis Scores, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var title = "Live Tennis Scores, Results, Schedules & Streams - ATP, WTA, Challenger & ITF - TNNS"
    var description = "Latest tennis live scores, results and schedules for Grand Slam, ATP, WTA, Challenger and ITF events."
    return(
      <>
      <Header title={this.state.title} description={this.state.description}/>
      <Background navigation={this.props.navigation} page={'Rankings'}>
      {loading ? <Loading/> : this.renderMain()}
      </Background>
      <BottomNavbar/>
      </>
    )
  }

  renderHeader = () => {
    var { header } = this.state
    return(
      <View style={[{width:'100%', paddingVertical:GLOBAL.padding*2, backgroundColor:GLOBAL.style.cardColor, borderColor:GLOBAL.style.borderColorSecondary, borderBottomWidth:1, overflow:'hidden', borderRadius:0}, MainStyles.flexCenter]}>
        <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth}, MainStyles.flexRow, MainStyles.flexStartCenter]}>
          <View style={[{width:'100%', paddingHorizontal:GLOBAL.padding}, MainStyles.flexCenterStart]}>
            <Text style={{fontSize:16, color:GLOBAL.style.color, fontFamily:GLOBAL.fonts.medium, marginBottom:0}}>
            {header?.name?.title}
            </Text>
            <Text style={{fontSize:28, color:GLOBAL.style.color, opacity:1, fontFamily:GLOBAL.fonts.black, textTransform:'uppercase'}}>
            {header?.name?.subtitle}
            </Text>
          </View>

          <Image style={{height:100, width:100, resizeMode:'contain', position:'absolute', right:0, bottom:-34}} source={{uri:header?.image}}/>
        </View>
      </View>
      )
  }

  changeTab = async (active_tab_data) => {
    var active_tab = active_tab_data.id
    await this.setState({loading:true})
    this.setState({active_tab, active_tab_data, loading:false})
  }

  renderMain = () => {
    var { tabs, active_tab, active_tab_data, _data, id, isLive, abbreviations, players, match_card, tabs_data, players } = this.state

    // tabs = [
    //   {id:'social', title:'Social', data_id:'social', type:'social'},
    //   {id:'bio', title:'Bio', data_id:'bio', type:'info'},
    //   {id:'form', title:'Form', type:'form'},
    //   {id:'rankings', title:'Rankings', data_id:'rankings', type:'info'},
    //   {id:'winloss', title:'Win/Loss', data_id:'winloss', type:'tabbed_info'},
    //   {id:'gs_performance', title:'Grand Slams', data_id:'gs_performance', type:'info'},
    // ]

    //
    var render = null
    if(active_tab_data.type === 'info'){ render = <Info data={tabs_data[active_tab_data.data_id]}/> }
    else if(active_tab_data.type === 'form'){ render = <Form players={[{id}]} hideTabs/> }
    else if(active_tab_data.type === 'tabbed_info'){ render = <Info tabbed={tabs_data[active_tab_data.data_id]}/> }
    else if(active_tab_data.type === 'social'){ render = <Twitter/> }

    return(
      <View style={[{width:'100%'}, MainStyles.flexStartCenter]}>
      {this.renderHeader()}
        <View style={{maxWidth:GLOBAL.maxWidth, width:'100%'}}>
          <View style={[{width:'100%', borderBottomWidth:1, borderBottomColor:GLOBAL.style.borderColorSecondary, overflow:'hidden'}, MainStyles.flexCenter]}>
            <Tabs tabs={tabs} onChange={this.changeTab} active_tab={active_tab}/>
          </View>
          <View style={[{width:'100%', maxWidth:GLOBAL.maxWidth, marginTop:0, borderColor:GLOBAL.style.borderColorSecondary}, MainStyles.flexRow, MainStyles.flexCenterStart]}>
            {render}
          </View>
        </View>
      </View>
    )
  }

  renderRight = () => {
    var { information, id, tabs_data, active_tab_data, bio } = this.state
    // return(
    //   <View style={{width:600, minHeight:200,borderLeftWidth:1, borderLeftColor:GLOBAL.style.borderColorSecondary}}>
    //
    //   </View>
    // )
    return null
  }

  render = () => {
    try{
      return this.renderContent()
    }catch(e){
      console.log(e)
      return null
    }
  }

}
